<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑     永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2022-09-21 17:34:05
 * @LastEditTime: 2022-10-17 17:19:39
 * @Description: 产品标号_附加型号
 * @FilePath: \BusinessPlatform\src\views\admin\dataDictionary\productLabel_AdditionalModels.vue
 -->

<template>
    <div class="productLabel" v-loading="loading">
        <div class="table_header">
            <table>
                <thead>
                    <tr>
                        <th style="width: 2.2rem;">
                            大类
                        </th>
                        <th style="width: 3rem;">
                            子类
                        </th>
                        <th>小类</th>
                    </tr>
                </thead>
            </table>
        </div>
        <div class="table_center">
            <table>
                <template v-for="(item, index) in dictionaryTree">
                    <tbody :key="`majorCategories_${index}`">
                        <tr>
                            <th style="width: 2.2rem;" :rowspan="item.children.length + 1">
                                <el-checkbox
                                    class="checkboxStyle"
                                    v-model="item.checkAll"
                                    :indeterminate="item.isIndeterminate"
                                    @change="checked => selectAllmajorCategories(checked, item)"
                                >
                                    <span class="name">{{ item.name }}</span>
                                </el-checkbox>
                            </th>
                        </tr>
                        <template v-for="(k, z) in item.children">
                            <tr :key="`subclass_${z}`">
                                <td style="width: 3rem;" class="subcategoryStyle">
                                    <el-checkbox
                                        v-model="k.checkAll"
                                        class="checkboxStyle"
                                        :indeterminate="k.isIndeterminate"
                                        @change="checked => selectAllSubcategories(checked, item, k)"
                                    >
                                        <span class="name" :title="k.name">{{ k.name }}</span>
                                    </el-checkbox>
                                </td>
                                <td class="subcategoryList">
                                    <el-checkbox-group
                                        v-model="k.categorySelected"
                                        @change="categorySelection(item, k)"
                                    >
                                        <template v-for="(x, j) in k.children">
                                            <el-checkbox :label="x.code" :key="`subcategory_${j}`">
                                                <!--<span class="name">{{ x.name }}</span>-->
                                                <span class="grade" :title="x.name">{{ x.name }}</span>
                                            </el-checkbox>
                                        </template>
                                    </el-checkbox-group>
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <th colspan="3" class="separatorLine"></th>
                        </tr>
                    </tbody>
                </template>
            </table>
        </div>
        <div class="table_fonter">
            <table>
                <tbody>
                    <tr>
                        <td style="width: 2.2rem;" class="subcategoryStyle">
                            <el-checkbox
                                v-model="checkAll"
                                class="checkAll"
                                :indeterminate="isIndeterminate"
                                @change="handleCheckAllChange"
                            >
                                <span class="name">全选</span>
                            </el-checkbox>
                        </td>
                        <td style="width: 3rem;" colspan="2">
                            <el-button type="primary" size="medium" @click="saveDictionary">
                                保存
                            </el-button>
                            <el-button size="medium" @click="resetSelection">
                                重置
                            </el-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'product-label',
    components: {},
    data() {
        return {
            loading: false,
            // 已选分类
            selectedClassification: [],
            // 所有分类
            dictionaryTree: [],
            checkAll: false,
            isIndeterminate: false,
        };
    },
    watch: {
        $route: {
            handler: function (val) {
                if (val.name === 'productLabel') {
                    this.initProductLabelPage();
                } else if (val.name === 'additionalModels') {
                    this.initAdditionalModelsPage();
                }
            },
            deep: true,
        },
    },
    created() {
        this.initProductLabelPage();
    },
    mounted() {},
    computed: {},
    methods: {
        // 初始化产品标号
        async initProductLabelPage() {
            this.loading = true;
            const selectedData = await this.initSelectedClassification('004,005');
            this.selectedClassification = JSON.parse(JSON.stringify(selectedData));

            const res = await this.getDictionaryTree(0);
            const treeData = res.filter(item => ['004', '005'].indexOf(item.code) > -1);

            this.dictionaryTree = this.processingData(treeData);
            this.loadSelectAll();
            this.$nextTick(() => {
                this.loading = false;
            });
        },

        // 初始化附加型号
        async initAdditionalModelsPage() {
            this.loading = true;
            const selectedData = await this.initSelectedClassification('501');
            this.selectedClassification = JSON.parse(JSON.stringify(selectedData));

            const treeData = await this.getDictionaryTree(5);
            this.dictionaryTree = this.processingData(treeData);
            this.loadSelectAll();
            this.loading = false;
        },

        // 处理数据
        processingData(treeData) {
            treeData.forEach(item => {
                if ((item.children || []).length > 0) {
                    item.children.forEach(k => {
                        k.isIndeterminate = false;
                        k.categorySelected = [];
                        if ((k.children || []).length > 0) {
                            k.children.forEach(x => {
                                if (this.selectedClassification.indexOf(x.code) > -1) {
                                    k.categorySelected.push(x.code);
                                }
                            });
                        }
                        if (k.categorySelected.length === k.children.length) {
                            k.checkAll = true;
                            item.isIndeterminate = true;
                        } else if (k.categorySelected.length > 0) {
                            k.checkAll = false;
                            k.isIndeterminate = true;
                            item.isIndeterminate = true;
                        } else {
                            k.checkAll = false;
                        }
                    });

                }
            });
            return treeData;
        },

        // 全选
        handleCheckAllChange(val) {
            if (val) {
                this.dictionaryTree.forEach(j => {
                    j.checkAll = true;
                    j.isIndeterminate = false;
                    j.children.forEach(k => {
                        k.checkAll = true;
                        const cityOptions = k.children.map(i => i.code);
                        k.categorySelected = cityOptions;
                        k.isIndeterminate = false;
                    });
                });
            } else {
                this.dictionaryTree.forEach(j => {
                    j.checkAll = false;
                    j.isIndeterminate = false;
                    j.children.forEach(k => {
                        k.checkAll = false;
                        k.categorySelected = [];
                        k.isIndeterminate = false;
                    });
                });
            }
        },

        // 大类全选
        selectAllmajorCategories(val, item) {
            if (val) {
                item.isIndeterminate = false;
                item.children.forEach(j => {
                    j.checkAll = true;
                    const cityOptions = j.children.map(i => i.code);
                    j.categorySelected = cityOptions;
                    j.isIndeterminate = false;
                });
            } else {
                item.isIndeterminate = false;
                item.children.forEach(j => {
                    j.checkAll = false;
                    j.categorySelected = [];
                    j.isIndeterminate = false;
                });
            }

            this.loadSelectAll();
        },

        // 子类全选
        selectAllSubcategories(val, item, k) {
            k.isIndeterminate = false;
            if (val) {
                const cityOptions = k.children.map(j => j.code);
                k.categorySelected = cityOptions;
            } else {
                k.categorySelected = [];
            }

            let selectedSubclasses = 0;
            let isIndeterminate = 0;
            item.children.forEach(j => {
                if (j.checkAll) {
                    selectedSubclasses = selectedSubclasses + 1;
                }
                if (j.isIndeterminate) {
                    isIndeterminate = isIndeterminate + 1;
                }
            });
            if (selectedSubclasses === 0) {
                item.checkAll = false;
                item.isIndeterminate = false;
            } else if (selectedSubclasses < item.children.length) {
                item.checkAll = false;
                item.isIndeterminate = true;
            } else {
                item.checkAll = true;
                item.isIndeterminate = false;
            }
            if (isIndeterminate > 0) {
                item.isIndeterminate = true;
            }

            this.loadSelectAll();
        },

        // 小类选择
        categorySelection(item, k) {
            const checkedCount = k.categorySelected.length;
            k.checkAll = checkedCount === k.children.length;
            k.isIndeterminate = checkedCount > 0 && checkedCount < k.children.length;

            let selectedSubclasses = 0;
            let isIndeterminate = 0;
            item.children.forEach(j => {
                if (j.checkAll) {
                    selectedSubclasses = selectedSubclasses + 1;
                }
                if (j.isIndeterminate) {
                    isIndeterminate = isIndeterminate + 1;
                }
            });
            if (selectedSubclasses === 0) {
                item.checkAll = false;
                item.isIndeterminate = false;
            } else if (selectedSubclasses < item.children.length) {
                item.checkAll = false;
                item.isIndeterminate = true;
            } else {
                item.checkAll = true;
                item.isIndeterminate = false;
            }
            if (isIndeterminate > 0) {
                item.isIndeterminate = true;
            }

            this.loadSelectAll();
        },

        // 加载全选
        loadSelectAll() {
            let selectAllClass = 0;
            this.dictionaryTree.forEach(j => {
                if (j.checkAll) {
                    selectAllClass = selectAllClass + 1;
                }
            });
            if (selectAllClass === 0) {
                this.checkAll = false;
                this.isIndeterminate = false;
            } else if (selectAllClass < this.dictionaryTree.length) {
                this.checkAll = false;
                this.isIndeterminate = true;
            } else {
                this.checkAll = true;
                this.isIndeterminate = false;
            }
        },

        // 重置选择
        resetSelection() {
            this.loading = true;
            this.dictionaryTree = this.processingData(this.dictionaryTree);
            this.loading = false;
        },

        // 保存
        async saveDictionary() {
            this.loading = true;
            const parentCodes = [];
            const codes = [];
            this.dictionaryTree.forEach(item => {
                if (item.checkAll || item.isIndeterminate) {
                    parentCodes.push(item.code);
                    codes.push(item.code);
                }
                (item.children || []).forEach(k => {
                    if (k.checkAll || k.isIndeterminate) {
                        codes.push(k.code);
                    }
                    codes.push(...k.categorySelected);
                });
            });
            const saveData = {
                parent_codes: parentCodes.toString(),
                codes: codes,
            };
            const res = await this.saveDictionaryRequest(saveData);
            if (res) {
                this.$message({
                    message: '保存成功！',
                    type: 'success',
                });
            }
            this.loading = false;
        },

        // 初始化已选分类
        initSelectedClassification(parent_codes) {
            const url = `/interfaceApi/baseinfo/dictionary_config/get_common_dictionary_codes?parent_codes=${parent_codes}`;
            return this.$axios.$get(url, { defEx: true });
        },

        // 拉取字典数据
        getDictionaryTree(type) {
            return this.$axios.$get(`/interfaceApi/baseinfo/dictionary_config/gettree?type=${type}`, { defEx: true });
        },

        // 保存请求
        saveDictionaryRequest(saveData) {
            return this.$axios.$post('/interfaceApi/baseinfo/dictionary_config/set_common_dictionary', saveData, { defEx: true });
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.productLabel
    height: 100%
    border 1px solid #D7D7D7
    table
        width 100%
        border-collapse collapse
        th,td
            height: 0.4rem;
            border: 1px solid #D7D7D7;
            border-left none
            font-size 0.16rem
            font-weight 400
            span
                color #5A7183
                font-size 0.16rem
                padding 0 0.1rem
                line-height 0.24rem
            .grade
                border-radius 0.02rem
                border 1px solid #E4E9F6
                background #EFF4FB
                width: 1rem;
                display: inline-block;
                margin-left: 0.05rem;
                padding: 0;
                text-align: center;
        thead
            tr
                th
                    &:last-child
                        border-right none
        tr
            td
                &:last-child
                    border-right none
        th
            color #022782
        td
            >>>.el-checkbox-group
                display flex
                flex-wrap: wrap;
                .el-checkbox
                    display flex
                    align-items center
                    margin-bottom: 0.1rem
                    margin-right 0.2rem
                    .el-checkbox__label
                        height 0.24rem
                        padding-left 0
                        .name
                            color #333333
                            font-size 0.14rem
                    &.is-checked
                        .el-checkbox__label
                            .name
                                color #1890FF
                                font-weight 500
                    .el-checkbox__inner
                        vertical-align top
                        width 0.18rem
                        height 0.18rem
                        display flex
                        justify-content center
                        align-items center
                        padding-bottom 0.04rem
                        border-radius 0.04rem
                        &:after
                            position unset
                            width 0.035rem
                            height 0.1rem
                            border-width 2px
                .grade
                    color #1890FF
                    background: #F8FAFD;
                    border: 1px solid #EBEEF6;
                    width: 0.8rem;
                    height: 0.24rem;
                    line-height: 0.24rem;
                    display: inline-block;
                    font-size 0.14rem
                    margin: 0 0.05rem;
                    padding: 0 0.1rem;
                    text-align: left;
                    white-space nowrap;
                    overflow hidden
                    text-overflow ellipsis
        .subcategoryStyle
            text-align: right
        .subcategoryList
            padding-top: 0.1rem
            padding-left: 0.1rem
        .separatorLine
            height: 3px
            padding 0
            background: #EDF0F6
            border none
        thead
            th
                background: #EDF0F5;
        tbody
            &:last-child
                .separatorLine
                    display none
            th
                background: #F8FAFD
    >>>.checkboxStyle
        display flex
        align-items center
        flex-direction row-reverse
        justify-content center;
        .name
            display inline-block
            width 1.3rem
            text-align right
            color #333333
            font-size 0.16rem
        .el-checkbox__input
            .el-checkbox__inner
                vertical-align top
                width 0.18rem
                height 0.18rem
                display flex
                justify-content center
                align-items center
                padding-bottom 0.03rem
                background #022782
                border-color #022782
                border-radius 0.04rem
                &:after
                    position unset
                    width 0.035rem
                    height 0.1rem
                    border-width 2px
                    margin-bottom 0.01rem
                &:before
                    position unset
                    width 1.04rem
                    border-width 2px
        &.is-checked
            .el-checkbox__label
                color #022782
                .name
                    font-weight bold!important
                .name,.grade
                    color #022782
                    font-weight 500
    .checkAll
        display flex
        align-items center
        flex-direction row-reverse
        justify-content center;
        .el-checkbox__input
            transform scale(1.3)
    .table_header
        table
            th
                border-top none
                border-bottom none
    .table_center
        height calc(100% - 0.9rem)
        overflow-y auto
        tr
            &:first-child
                td
                    &:last-child
                        border-top none
    .table_fonter
        height 0.5rem
        position: relative
        z-index 2
        box-shadow 0px -2px 6px 0px rgba(0,0,0,0.1000)
        table
            td
                height 0.5rem
            .el-button
                margin-left 0.1rem
                padding: 0 0.3rem
                line-height: 0.36rem
                font-size 0.14rem
</style>
